import React from "react";
import im from './assets/Benny-Button-favicon.png';

export default function Header() {
  return <div className="bot-header">
    <div className="bot-header-icon">
      <img src={im} alt="ChatBot Icon" width={60} height={60} />
    </div>
    <div className="bot-header-text-invert">&nbsp;Benny Button B🤖t</div>
    </div>;
}
