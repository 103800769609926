import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import initialiseFirebaseApp from "./initialiseFirebaseAnalytics";
import './scss/styles.scss';

window.__DEV__ = process.env.NODE_ENV !== 'production';
window.__DEBUG_MODE__ = process.env.NODE_ENV !== 'production';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10000
    }
  }
});


createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(); // we remove the sentry stuff for now...

// initialise the firebase analytics part
initialiseFirebaseApp();
