import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Loading from "./components/Loading";
import { useAuth, UserProvider } from "./contexts/UserContext";
import { canAccessDashboard } from "./models/User";
import isMobile from "./utils/isMobile";

const Home = lazy(() => import("./pages/home"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const History = lazy(() => import("./pages/dashboard/History"));
const Signin = lazy(() => import("./pages/sign-in"));
const Signup = lazy(() => import("./pages/sign-up"));
const About = lazy(() => import("./pages/about"));
const Account = lazy(() => import("./pages/account"));
const ResetPassword = lazy(() => import("./pages/reset-password"));
const Onboarding = lazy(() => import("./pages/onboarding"));
const NewPassword = lazy(() => import("./pages/new-password"));
const NewEmail = lazy(() => import("./pages/new-email"));
const Checkin = lazy(() => import("./pages/check-in"));
const Focus = lazy(() => import("./pages/focus"));
const Actions = lazy(() => import("./pages/actions"));
const Review = lazy(() => import("./pages/review"));
const Resource = lazy(() => import("./pages/resource"));
const NotFound = lazy(() => import("./pages/404"));
const MobileWarning = lazy(() => import("./pages/mobile-warning"));
const SupportServices = lazy(() => import("./pages/support-services"));
const CheckAuth = lazy(() => import("./pages/check-auth"));

const PublicRoute = (props) => {
  const { userToken } = useAuth();


  if (userToken && ["/sign-in", "/sign-up"].includes(props.path)) {
    return <Redirect to="/" />;
  }

  if (isMobile() && props.path !== "/sign-up") {
    return <MobileWarning />;
  }

  return <Route {...props} />;
};

const ProtectedRoute = ({ redirectPath = "/sign-in", children, ...rest }) => {
  const { userToken } = useAuth();
  const history = useHistory();

  if (isMobile()) {
    return <MobileWarning />;
  }

  if (!userToken) {
    return <Redirect to={{
      pathname: redirectPath,
      state: { 
        from: history.location.pathname
       }
    }} />;
  }

  return <Route {...rest}>{children}</Route>;
};

const DashboardRoute = ({ children, ...rest }) => {
  const { user, userToken } = useAuth();

  if (userToken && !user) {
    return <Loading isFullScreen />;
  }

  if (canAccessDashboard(user)) {
    return <ProtectedRoute {...rest}>{children}</ProtectedRoute>;
  }

  return <Redirect to="/" />;
};

const HistoryRoute = ({ children, ...rest }) => {
  const { user, userToken } = useAuth();

  if (userToken && !user) {
    return <Loading isFullScreen />;
  }

  return <ProtectedRoute {...rest}>{children}</ProtectedRoute>;;
};

const IndexRoute = ({ children, ...props }) => {
  const { userToken, user } = useAuth();

  const hasCheckedIn = user?.data?.checkedIn;
  const hasSetFocus = user?.data?.focusSet;

  const shouldOnboarding = !hasCheckedIn || !hasSetFocus;

  if (!userToken) {
    return <Redirect to="/sign-in" />;
  }

  if (!user) {
    return <Loading isFullScreen />;
  }

  if (shouldOnboarding) {
    return <Redirect to="/onboarding" />;
  }

  return <Route {...props}>{children}</Route>;
};

function App() {
  return (
    <Suspense fallback={<Loading isFullScreen />}>
      <Router>
        <UserProvider>
          <Switch>
            <PublicRoute path="/check-auth">
              <CheckAuth />
            </PublicRoute>
            <IndexRoute path="/" exact>
              <Home />
            </IndexRoute>
            <PublicRoute path="/sign-up">
              <Signup />
            </PublicRoute>
            <PublicRoute path="/sign-in">
              <Signin />
            </PublicRoute>
            <PublicRoute path="/about">
              <About />
            </PublicRoute>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <HistoryRoute path="/history">
              <History />
            </HistoryRoute>
            <DashboardRoute path="/dashboard">
              <Dashboard />
            </DashboardRoute>
            <ProtectedRoute path="/new-password">
              <NewPassword />
            </ProtectedRoute>
            <ProtectedRoute path="/new-email">
              <NewEmail />
            </ProtectedRoute>
            <ProtectedRoute path="/account">
              <Account isProtected />
            </ProtectedRoute>
            <ProtectedRoute path="/onboarding">
              <Onboarding isProtected />
            </ProtectedRoute>
            <ProtectedRoute path="/check-in">
              <Checkin isProtected />
            </ProtectedRoute>
            <ProtectedRoute path="/focus">
              <Focus />
            </ProtectedRoute>
            <ProtectedRoute path="/actions">
              <Actions isProtected />
            </ProtectedRoute>
            <ProtectedRoute path="/review">
              <Review isProtected />
            </ProtectedRoute>
            <ProtectedRoute path="/resources/:resourceId">
              <Resource isProtected />
            </ProtectedRoute>
            <ProtectedRoute path="/support-services">
              <SupportServices />
            </ProtectedRoute>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </UserProvider>
      </Router>
    </Suspense>
  );
}

export default App;
