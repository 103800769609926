import React, { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { ProgressStates, useProgressUpdate } from "../hooks/useProgressUpdate";
import API, { getAuthToken } from "../services/api";
import ChatBotComponent from "../ChatBotComponent";

export const UserContext = React.createContext();

const pathRedirect = ['/check-in', '/focus', '/review']

export const UserProvider = ({ children }) => {
  const history = useHistory();
  const [status, setStatus] = useState("idle");
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [userToken, setUserToken] = useState(getAuthToken());
  const [, setProgress] = useProgressUpdate();
  const {
    data: userData,
    isLoading,
    refetch: refetchUser,
  } = useQuery("user", () => API.getUser({ userToken }), {
    enabled: !!userToken,
    refetchOnWindowFocus: true,
    retry: 1,
    onError: (err) => {
      resetUser();
    },
  });

  const login = ({ email, password, previousPathName }, { redirectOnSuccess = true } = {}) => {
    setStatus("loading");
    setError(null);

    API.login(email, password)
      .then((response) => {
        const idToken = response?.data?.idToken;

        setStatus("success");

        API.setAuthorizationHeader(idToken);
        API.sendOSInfo('LOGIN');

        setUserToken(idToken);
        setProgress(ProgressStates.UP1);

        if (redirectOnSuccess) {
          if (pathRedirect.includes(previousPathName)) {
            history.replace(previousPathName)
          } else {
            history.replace("/");
          }
        }
      })
      .catch((err) => {
        setStatus("error");

        if (err.statusCode === 401) {
          setError("Email or password is incorrect");
        } else {
          setError(err.message);
        }
      });
  };

  const resetUser = useCallback(() => {
    API.sendOSInfo('LOGOUT');
    API.removeAuthorizationHeader();
    setUser(undefined);
    setUserToken(undefined);
    window.location.reload();
  }, [setUser, setUserToken]);

  useEffect(() => {
    if (userToken) {
      API.setAuthorizationHeader(userToken);
    }
  }, [userToken]);

  useEffect(() => {
    if (userData?.data?.body) {
      setUser(userData.data.body);
      localStorage.setItem(
        "organisationTag",
        userData?.data?.body?.data?.organisationTag
      );
    }
  }, [userData]);

  return (
    <UserContext.Provider
      value={{
        isLoading,
        user,
        userToken,
        setUser,
        setUserToken,
        resetUser,
        refetchUser,
        login,
        error,
        status,
      }}
    >
      {children}
      {user && userToken ? <ChatBotComponent /> : null}
    </UserContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(UserContext);
};
