import axios from 'axios';
import ScreenContentfulModel from '../models/ContentfulModel';
import DefaultResponseHandler from './ResponseHandler';
const queryString = require('query-string');
const contentful = require('contentful');
const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_CONTENTFUL_DOMAIN,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer g4ylYi7kNFEhc_ToEwgkv-8kYyraowB2nZXJGi_x9vM`
  }
});
/**
 * Contentful
 */
const apiController = `/spaces/v98oetbd7ayp/entries`;
export const CONTENT_TYPES = {
  ActionStatuses: 'actionStatus',
  CheckInQuestions: 'checkInQuestions',
  SupportServices: 'supportServices',
  SetFocus: 'setFocus',
  Resources: 'article',
  Onboarding: 'onboardingCopy',
  FocusArea: 'focusAreas',
  Priorities: 'priorities',
  Actions: 'actions',
  About: 'aboutCopy',
  RateActions: 'rateActionQuickResponse',
  Review: 'reviewProgress',
  InspirationCarousel: 'inspirationCarousel',
  ProgressUpdate: 'progressUpdate'
};

const API_CONTENT = () => `${apiController}`;
/**
 * Contentful
 */

let contentfulClient = null;
const cdn = () => {
  if ( contentfulClient === null ) {
    contentfulClient = contentful.createClient( {
      // space: 'n64bibf2ruu3',
      // accessToken: 'RTnCeMteF-PWAqlEWpF5IbHZE16N_rO_cR60MjPdqls',
      space: 'v98oetbd7ayp',
      accessToken: 'z6AIAvpQUt5adeE67RmFPXL1s8bYMzzRR5V2JB4Aw4Y',
    } );
  }
  return contentfulClient;
}

let responseHandler;
const defaultResponseHandler = new DefaultResponseHandler();





/**
 * Utilities
 */
const executeRequest = (promise) => {
  return new Promise((resolve, reject) => {
    const handler =
      responseHandler && typeof responseHandler === 'function'
        ? responseHandler
        : defaultResponseHandler;
    promise
      .then((response) => {
        handler.handleSuccess(response, resolve, reject);
        responseHandler = undefined;
      })
      .catch((error) => {
        handler.handleError(error, reject);
        responseHandler = undefined;
      });
  });
};

const doGetRequest = (url, params, config) => {
  const organisationTag = window.localStorage.getItem('organisationTag') ?? 'default';

  if (params) {
    url += `?${queryString.stringify(params)}&limit=200&metadata.tags.sys.id[in]=default,${organisationTag}`;
  }
  return executeRequest(apiInstance.get(url, config));
};

const doGetRequestWithTag = (url, params, config) => {
  const organisationTag = window.localStorage.getItem('organisationTag') ?? 'default';

  if (params) {
    url += `?${queryString.stringify(params)}&limit=200&metadata.tags.sys.id[in]=default,${organisationTag}`;
  }
  return executeRequest(apiInstance.get(url, config));
};


async function getWellbeingResourceArticle( contentfulEntryId ) {
  let response = null;
  let error = null;
  await cdn().getEntry( contentfulEntryId.trim() )
      .then((entry) => {
        // console.log( "received result from Contentful API: " + entry )
        response = entry
      })
      .catch((err) => {
        console.error(err.message)
        error = err
        return error``
      })
  return response
}

/**
 * Utilities
 */

const getContentInPage = (contentType, config) => {
  return doGetRequest(
    API_CONTENT(),
    { content_type: contentType },
    config
  ).then((response) => {
    response.data = ScreenContentfulModel.parseFromJson(response.data);
    return response;
  });
};

export const getContent = (contentType, config) => {
  return doGetRequest(API_CONTENT(), { content_type: contentType }, config);
};

export const getContentWithTag = (contentType, config) => {
  return doGetRequestWithTag(API_CONTENT(), { content_type: contentType }, config);
};

const exported = {
  apiInstance,
  CONTENT_TYPES,
  getContentInPage,
  getContent,
  getContentWithTag,
  getWellbeingResourceArticle
};

export default exported;
