import axios from "axios";
import cookie from "js-cookie";
import DefaultResponseHandler from "./ResponseHandler";
import {
  browserName,
  fullBrowserVersion,
  osVersion,
  osName,
} from "react-device-detect";
// const queryString = require('query-string');
import qs from 'querystring';
const TOKEN_KEY = "customerToken";

const baseURL = process.env.REACT_APP_API_DOMAIN;
// const baseURL = 'https://api.bennybutton.cloud/';
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  // "Set-Cookie": process.env.REACT_APP_API_COOKIE,
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true
};

const apiInstance = axios.create({
  baseURL,
  headers,
});

const apiWithKey = axios.create({
  baseURL,
  headers: {
    ...headers,
    "BB-API-ACCESS-KEY": process.env.REACT_APP_API_ACCESS_KEY,
    // "BB-API-ACCESS-KEY": 'FNQSWFG4H18LBB2ULPROD',
  },
});

const apiController = "/v1/";
const API_LOGIN = () => `${apiController}session/login`;
const API_SIGNUP = () => `${apiController}session/signup`;
const API_RESET_PASSWORD = () => `${apiController}session/reset-password`;
const API_USER = () => `${apiController}user`;
const API_CHANGE_PASSWORD = () => `${apiController}session/change-password`;
const API_CHANGE_EMAIL = () => `${apiController}session/update-email`;
const API_CHECKINS = () => `${apiController}check-in`;
const API_CREATE_CHECKINS = () => `${apiController}check-in`;
const API_LATEST_FOCUS = () => `${apiController}focus/latest`;
const API_SET_FOCUS = () => `${apiController}focus`;
const API_MISSION = () => `${apiController}user/mission-statement`;
const API_ACTION = () => `${apiController}action`;
const API_DELETE_ACTION = (id) => `${apiController}action/${id}`;

const API_REVIEW = () => `${apiController}review`;
const API_GET_ALL_REVIEWS = () => `${apiController}review/my-progress-records`;
const API_GET_ALL_FOCUSES = () => `${apiController}focus`;
const API_GET_FOCUS_AREAS = () => `${apiController}metadata/focus-areas`;
const API_GET_PRIORITIES_METADATA = (itemCodes) =>
  `${apiController}metadata/list/${itemCodes}`;

const API_ORGANISATIONS = () =>
  `${apiController}checkpoint-admin/all-organisations`;

const API_ORGANISATION = (tag) => `${apiController}organisation/${tag}`;

const API_SINGLE_ORGANISATION = (tag) =>
  `${apiController}checkpoint-admin/organisation/${tag}`;

const API_ORGANISATION_TIERS = (tag) =>
  `${apiController}checkpoint-admin/organisation-tiers/${tag}`;

const API_COUNTRIES = () =>
  `${apiController}checkpoint-admin/all-iso-countries`;

const API_GET_RESOURCE_LIKE = (resourceId) =>
  `${apiController}resource/latest/${resourceId}`;

const API_GET_RESOURCE_COMPLETE = (resourceId) =>
  `${apiController}resource/latest/${resourceId}`;

const API_LIKE_RESOURCE = () => `${apiController}resource`;

const API_GET_LATEST_REVIEW_BY_FOCUS = (focusFlowId) =>
  `${apiController}review/latest/${focusFlowId}`;

const API_SET_RESOURCE_CLICKED = () => `${apiController}resource`;

const API_GET_DASHBOARD_CHECKIN = () => `${apiController}analytics/checkin`;
const API_GET_DASHBOARD_FOCUS_AREA = () =>
  `${apiController}analytics/focus-area`;

const API_GET_PROGRESS_SUMMARY = () =>
  `${apiController}analytics/action-progress-summary`;

const API_CHECK_PERMISSION = () => `${apiController}user/reporting-permission`;
const API_SEND_OS_INFO = () => `${apiController}session/audit`;

const API_GET_HISTORY = () =>
  `${apiController}check-in/user-history`;

let responseHandler;
const defaultResponseHandler = new DefaultResponseHandler();

/**
 * Utilities
 */

export const getAuthToken = () => cookie.get(TOKEN_KEY);

const setAuthorizationHeader = (token) => {
  if (!apiInstance.defaults.headers) {
    apiInstance.defaults.headers = {};
  }
  if (token) {
    apiInstance.defaults.headers.Authorization = `Bearer ${token}`;
    cookie.set(TOKEN_KEY, token, { expires: 2 });
  }
};

const removeAuthorizationHeader = () => {
  if (apiInstance.defaults.headers) {
    delete apiInstance.defaults.headers["Authorization"];
    cookie.remove(TOKEN_KEY);
  }
};

const setTemporaryResponseHandler = (handler) => {
  responseHandler = handler;
};

const executeRequest = (promise) => {
  return new Promise((resolve, reject) => {
    const handler =
      responseHandler && typeof responseHandler === "function"
        ? responseHandler
        : defaultResponseHandler;
    promise
      .then((response) => {
        handler.handleSuccess(response, resolve, reject);
        responseHandler = undefined;
      })
      .catch((error) => {
        // console.log({ error });
        handler.handleError(error, reject);
        responseHandler = undefined;

        if (error?.response?.status === 401) {
          removeAuthorizationHeader();
        }
      });
  });
};

const doGetRequest = (url, params, config) => {
  const api = config?.withKey ? apiWithKey : apiInstance;
  let tierParam = {};
  let tierKey = "";
  if (params) {
    for (const i in params) {
      if (i.includes("tier")) {
        tierParam[i] = params[i];
        tierKey = i;
        delete params[i];
      }
    }
  }
  url += `?${qs.stringify(params)}`;
  if (tierKey) {
    for (const i in tierParam) {
      url = `${url}&${i}=${tierParam[i]}`;
      break;
    }
  }
  return executeRequest(api.get(url, config));
};

const doPostRequest = (url, payload, config) => {
  const api = config?.withKey ? apiWithKey : apiInstance;
  return executeRequest(api.post(url, payload, config));
};

const doPutRequest = (url, payload, { withKey, signupKey } = {}) => {
  const api = withKey ? apiWithKey : apiInstance;
  const headers = signupKey ? { "BB-ORG-SIGNUP-KEY": signupKey } : {};

  return executeRequest(api.put(url, payload, { headers }));
};

const doDeleteRequest = (url, payload, config) => {
  return executeRequest(apiInstance.delete(url, payload, config));
};

/**
 * Utilities
 */

/**
 * Authentication
 */
const login = (email, password) => {
  return doPostRequest(API_LOGIN(), {
    email: email,
    password: password,
  });
};

const signup = ({ signupKey, ...payload }) => {
  return doPutRequest(API_SIGNUP(), payload, {
    withKey: true,
    signupKey,
  });
};

const resetPassword = (email) => doPostRequest(API_RESET_PASSWORD(), { email });
/**
 * Authentication
 */

/**
 * Authenicated
 */
const getUser = ({ userToken }) => {
  if (userToken) {
    setAuthorizationHeader(userToken);
  }

  return doGetRequest(API_USER());
};

const changePassword = ({ password, existingPassword, email }) => {
  return doPostRequest(API_CHANGE_PASSWORD(), {
    email,
    password,
    oldPassword: existingPassword,
  });
};

export const changeEmail = ({ email }) => {
  return doPostRequest(API_CHANGE_EMAIL(), {
    email,
  });
};
export const getPermission = () => doGetRequest(API_CHECK_PERMISSION());

const sendOSInfo = async (eventType) => {
  let latitude = 0;
  let longitude = 0;
  const coords = localStorage.getItem("coords");
  if (coords) {
    const data = JSON.parse(coords);
    latitude = data.latitude;
    longitude = data.longitude;
  }

  const param = {
    fcmToken: null,
    uniqueDeviceIdentification: "checkpoint desktop build version",
    deviceMsisdn: "",
    eventType,
    browserInformation: `${browserName} ${fullBrowserVersion}`,
    deviceOperatingSystem: `${osName} ${osVersion}`,
    latitude,
    longitude,
  };
  doPostRequest(API_SEND_OS_INFO(), param);
};

const getCheckIns = () => doGetRequest(API_CHECKINS());

const createCheckIn = (data) => doPostRequest(API_CREATE_CHECKINS(), data);

const setFocus = (data) => doPutRequest(API_SET_FOCUS(), data);

export const setMission = (data) => doPostRequest(API_MISSION(), data);
export const getMission = (data) => doGetRequest(API_MISSION(), data);

export const createAction = (data) => doPutRequest(API_ACTION(), data);

export const getFocus = (data) => doGetRequest(API_LATEST_FOCUS(), data);
export const getAllFocuses = () => doGetRequest(API_GET_ALL_FOCUSES());

export const getActions = () => doGetRequest(API_ACTION());

export const deleteAction = (id) => doDeleteRequest(API_DELETE_ACTION(id));

export const getOrganisations = () =>
  doGetRequest(API_ORGANISATIONS(), {}, { withKey: true });

export const getOrganisationTiers = (tag) =>
  doGetRequest(API_ORGANISATION_TIERS(tag), {}, { withKey: true });

export const getOrganisation = (tag) => {
  doGetRequest(API_ORGANISATION(tag), {}, { withKey: true });
};

export const getSingleOrganisation = (tag) =>
  doGetRequest(API_SINGLE_ORGANISATION(tag), {}, { withKey: true });

export const createReview = (data) => doPutRequest(API_REVIEW(), data);
export const getAllReviews = () => doGetRequest(API_GET_ALL_REVIEWS());
export const getFocusAreas = () => doGetRequest(API_GET_FOCUS_AREAS());
export const getPrioritiesMetadata = (itemCodes) =>
  doGetRequest(API_GET_PRIORITIES_METADATA(itemCodes));

export const updateAccount = (data) => doPutRequest(API_USER(), data);

export const getResourceLike = (resourceId, param) =>
  doGetRequest(API_GET_RESOURCE_LIKE(resourceId), param);

export const getResourceComplete = (resourceId, param) =>
  doGetRequest(API_GET_RESOURCE_COMPLETE(resourceId), param);

export const likeResource = ({ resourceId, isLike, uid }) => {
  doPostRequest(API_LIKE_RESOURCE(), {
    type: "LIKE",
    eventValue: isLike,
    resourceItemCode: resourceId,
    uid,
  });
};

export const completeResource = ({ resourceId, isComplete, uid }) => {
  doPostRequest(API_LIKE_RESOURCE(), {
    type: "COMPLETE",
    eventValue: isComplete,
    resourceItemCode: resourceId,
    uid,
  });
};

export const getLatestReviewByFocus = (focusFlowId) =>
  doGetRequest(API_GET_LATEST_REVIEW_BY_FOCUS(focusFlowId));

export const updateReview = (payload) => doPutRequest(API_REVIEW(), payload);

export const getAllCountries = () =>
  doGetRequest(API_COUNTRIES(), null, { withKey: true });

export const setResourceClicked = (data) =>
  doPostRequest(API_SET_RESOURCE_CLICKED(), data);

export const getDashboardCheckIn = (tag, options, tiers) =>
  doGetRequest(API_GET_DASHBOARD_CHECKIN(), {
    "organisation-tag": tag === "default" ? "all" : tag,
    ...options,
    ...tiers,
  });

export const getDashboardFocusArea = (tag, options, tiers) =>
  doGetRequest(API_GET_DASHBOARD_FOCUS_AREA(), {
    "organisation-tag": tag === "default" ? "all" : tag,
    ...options,
    ...tiers,
  });

export const getProgressSummary = (tag, options, tiers) =>
  doGetRequest(API_GET_PROGRESS_SUMMARY(), {
    "organisation-tag": tag === "default" ? "all" : tag,
    ...options,
    ...tiers,
  });

export const getHistoryCheckIn = (date) =>
  doGetRequest(API_GET_HISTORY(), {
    ...date,
  });

/**
 * Authenicated
 */

const exported = {
  apiInstance,
  setTemporaryResponseHandler,
  setAuthorizationHeader,
  removeAuthorizationHeader,
  login,
  getUser,
  signup,
  resetPassword,
  changePassword,
  getCheckIns,
  createCheckIn,
  setFocus,
  getPermission,
  sendOSInfo,
  getHistoryCheckIn
};

export default exported;
