export class ContentfulAsset {
  id;
  assetUrl;

  constructor(id, assetUrl) {
    this.id = id;
    this.assetUrl = assetUrl;
  }
}
export class ContentfulModel {
  screenName;
  itemCode;
  headerText;
  bodyText;
  sliderMaxText;
  sliderMinText;
  title;
  link;
  hashtag;
  description;
  thumbnail;
  icon;
  focusAreaParentIds;
  priorityParentIds;
  id;

  static parseFromJson = (data) => {
    const { screenName, itemCode, header, body, sliderMax, sliderMin, title, link, hashtag, description, focusAreaParent, priorityParent } = data;
    const contenful = new ContentfulModel();
    contenful.screenName = screenName;
    contenful.itemCode = itemCode;
    contenful.headerText = header;
    contenful.bodyText = body;
    contenful.sliderMaxText = sliderMax;
    contenful.sliderMinText = sliderMin;
    contenful.title = title;
    contenful.link = link;
    contenful.hashtag = hashtag;
    contenful.description = description;
    if (focusAreaParent && Array.isArray(focusAreaParent)) {
      contenful.focusAreaParentIds = {};
      focusAreaParent.forEach(item => contenful.focusAreaParentIds[item.sys.id] = item.sys.id);
    }

    if (priorityParent && Array.isArray(priorityParent)) {
      contenful.priorityParentIds = {};
      priorityParent.forEach(item => contenful.priorityParentIds[item.sys.id] = item.sys.id);
    }

    return contenful;
  }
}

export default class ScreenContentfulModel {
  items;

  constructor() {
    this.items = new Map();
  }

  static parseFromJson = (data) => {
    const obj = new ScreenContentfulModel();
    const { items, includes } = data;
    if (items && Array.isArray(items)) {
      const { Asset } = includes || {};
      const AssetById = {};

      if (Asset && Array.isArray(Asset)) {
        Asset.forEach(item => {
          const { sys, fields } = item;
          const url = `https:${fields?.file.url ?? ''}`;
          const id = sys?.id ?? '';
          AssetById[id] = new ContentfulAsset(id, url);
        });
      }

      items.forEach(item => {
        const { fields, sys } = item;
        if (fields) {
          const contenful = ContentfulModel.parseFromJson(fields);
          const assetId = fields?.thumbnail?.sys.id ?? '';
          const iconAssetId = fields?.icon?.sys.id ?? '';
          contenful.thumbnail = AssetById[assetId];
          contenful.icon = AssetById[iconAssetId];
          contenful.id = sys?.id ?? '';
          obj.items.set(fields.itemCode, contenful);
        }
      });
    }
    return obj;
  }
}